<template>
  <Modal @close="$emit('close')" classes="max-w-3xl">
    <div class="flex flex-col h-full">
      <div v-if="submitted">
        <h1 class="heading font-bold">Thank you for your feedback!</h1>
        <Button class="mt-5" primary @click.prevent="$emit('close')"> Close </Button>
      </div>
      <div v-else>
        <h1 class="font-bold mb-2">Please provide feedback on this list</h1>
        <textarea
          class="border border-gray-300 rounded-lg w-full m-2"
          v-model="founderListFeedback"
        />
        <div class="mt-2">
          <Button
            class="border border-fr-green dark:border-fr-green-dark text-fr-green dark:text-fr-green-dark rounded-lg p-4"
            primary
            :disabled="!founderListFeedback"
            @click.prevent="sendFounderFeedback"
          >
            Send Feedback
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal'
import CustomerList from '@/services/customerLists'

export default {
  name: 'SendFounderListFeedbackModal',
  components: {
    Modal,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      founderListFeedback: '',
      submitted: false,
    }
  },
  methods: {
    async sendFounderFeedback() {
      await CustomerList.api.update(this.list.id, {
        founderListFeedback: this.founderListFeedback,
      })
      this.submitted = true
    },
  },
}
</script>

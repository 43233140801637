<template>
  <div
    class="fixed flex z-50 top-0 left-0 w-full h-full overflow-auto bg-black/50"
    @click.self="$emit('close')"
  >
    <div
      class="bg-white my-24 mx-auto p-10 border w-4/5 rounded-lg box-border h-max"
      :class="{ [classes]: true }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    classes: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <Modal @close="$emit('close')" classes="max-w-3xl">
    <div class="flex flex-col h-full">
      <div>
        <h1 class="heading font-bold mb-6">{{ modalHeading }}</h1>
        <TextAreaField v-model:value="notes" placeholder="Notes about this intro..." />
        <div class="mt-2">
          <Button @click="addNotesToOpportunity()"> Save </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Opportunity from '@/services/opportunities'

import Modal from '@/components/modals/Modal'
import TextAreaField from '@/components/inputs/TextAreaField'
import Button from '@/components/inputs/Button'

export default {
  name: 'AddOpportunityNotesModal',
  components: {
    Modal,
    TextAreaField,
    Button,
  },
  props: {
    opportunity: {
      type: Opportunity,
      required: true,
    },
    field: {
      type: String,
      required: true,
      validator(value) {
        return ['friendlyNotes', 'founderNotes'].includes(value)
      },
    },
  },
  data() {
    return {
      notes: this.opportunity[this.field] || '',
    }
  },
  computed: {
    modalHeading() {
      return this.opportunity[this.field]
        ? 'Edit notes about this intro request'
        : 'Add notes about this intro request'
    },
  },
  watch: {
    opportunity: {
      immediate: true,
      handler(newVal) {
        this.notes = newVal[this.field] || ''
      },
    },
  },
  methods: {
    async addNotesToOpportunity() {
      try {
        await Opportunity.api.update(this.opportunity.id, { [this.field]: this.notes })
        const copy = new Opportunity(JSON.parse(JSON.stringify(this.opportunity)))
        copy[this.field] = this.notes
        this.$emit('update', copy)
        this.$emit('close')
      } catch (error) {
        console.error('Error updating notes:', error)
      }
    },
  },
}
</script>

<template>
  <button
    :disabled="disabled"
    :type="type"
    class="inline-flex items-center px-4 py-2 text-sm font-medium text-center border border-gray-300 rounded-lg"
    :class="{
      'opacity-50 cursor-not-allowed': disabled,
      'bg-blue-500 text-white': primary,
      'hover:bg-blue-700': primary && !disabled,
      'bg-fr-green text-white': !primary,
      'hover:bg-fr-green-dark': !primary && !disabled,
      'bg-red-500 text-white': danger,
      [classes]: true,
    }"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    classes: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>

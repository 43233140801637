<template>
  <button
    v-if="isSelected"
    :disabled="disabled"
    @click="selectForOutreach(opportunity.id, false)"
    class="text-green-600 font-semibold"
  >
    Yes
  </button>
  <button
    v-else
    :disabled="disabled"
    @click="selectForOutreach(opportunity.id, true)"
    class="text-amber-700 font-semibold"
  >
    No
  </button>
</template>

<script>
import Opportunity from '@/services/opportunities'

export default {
  name: 'OpportunityFounderSelection',
  props: {
    opportunity: {
      type: Opportunity,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelected: this.selected,
    }
  },
  methods: {
    async selectForOutreach(opportunityId, value) {
      await Opportunity.api.update(opportunityId, { founderSelected: value })
      this.isSelected = value
    },
  },
}
</script>

<template>
  <div
    tabindex="-1"
    class="flex z-10 gap-8 justify-between items-center mb-3 py-3 px-4 w-full bg-blue-50 border border-b border-blue-200 sm:items-center dark:border-blue-700 lg:py-4 dark:bg-blue-800"
  >
    <p class="text-sm font-light text-blue-500 dark:text-blue-400">
      <slot></slot>
    </p>
  </div>
</template>

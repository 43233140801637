<template>
  <div
    tabindex="-1"
    class="flex z-10 gap-8 justify-between items-center mb-3 py-3 px-4 w-full bg-green-50 border border-b border-green-200 sm:items-center dark:border-green-700 lg:py-4 dark:bg-green-800"
  >
    <p class="text-sm font-light text-green-500 dark:text-green-400">
      <slot></slot>
    </p>
  </div>
</template>

<template>
  <div v-if="loading" class="flex flex-col items-center">
    <LoadingSpinner />
  </div>
  <div v-else-if="!list.datetimeSalesforceHandoffCompleted" class="mt-14">
    <p>We are still processing the hand-off from SalesForce for this List.</p>
    <p>This can take up to 10 minutes.</p>
  </div>
  <div v-else class="flex flex-col max-w-screen-xl mx-auto mt-8">
    <SuccessBanner
      v-if="list.statusIsApprovedByFounder || list.statusIsSubmittedToFriendlies"
      class="mb-4"
    >
      You have already submitted this list to First Round.
    </SuccessBanner>
    <InfoBanner v-else class="mb-4">
      Toggle Yes/No under 'Select for Outreach' for each contact. When done, press 'Confirm
      Selections'.
    </InfoBanner>
    <div class="relative flex flex-row mt-8 mb-6">
      <Button
        class="mx-auto"
        primary
        :disabled="!list.isEditableByFounder || attemptingUpdate"
        @click="confirmListSelections"
      >
        Confirm List Selections
      </Button>
    </div>
    <table class="w-full table-fixed border-collapse border-0 border-slate-400 text-sm mb-6">
      <thead>
        <tr>
          <th
            class="border border-slate-300 cursor-pointer"
            @click="updateOrdering('contact__name')"
          >
            Name
            <span v-if="currentOrdering === 'contact__name'" class="sort-indicator">▲</span>
            <span v-if="currentOrdering === '-contact__name'" class="sort-indicator">▼</span>
            <span v-if="currentOrdering !== '-contact__name' && currentOrdering !== 'contact__name'"
              >▲▼</span
            >
          </th>
          <th
            class="border border-slate-300 cursor-pointer"
            @click="updateOrdering('contact__title')"
          >
            Title
            <span v-if="currentOrdering === 'contact__title'" class="sort-indicator">▲</span>
            <span v-if="currentOrdering === '-contact__title'" class="sort-indicator">▼</span>
            <span
              v-if="currentOrdering !== '-contact__title' && currentOrdering !== 'contact__title'"
              >▲▼</span
            >
          </th>
          <th class="border border-slate-300" @click="updateOrdering('contact__company_name')">
            Company Name
            <span v-if="currentOrdering === 'contact__company_name'" class="sort-indicator">▲</span>
            <span v-if="currentOrdering === '-contact__company_name'" class="sort-indicator"
              >▼</span
            >
            <span
              v-if="
                currentOrdering !== '-contact__company_name' &&
                currentOrdering !== 'contact__company_name'
              "
              >▲▼</span
            >
          </th>
          <th class="border border-slate-300">Admin Notes</th>
          <th class="border border-slate-300">Select for Outreach?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="o in opportunities.list"
          :key="o.id"
          :class="{
            'bg-gray-200 text-gray-500': !list.isEditableByFounder && !o.founderSelected,
          }"
        >
          <td class="border border-slate-300">
            <a
              v-if="o.contactLinkedinProfile"
              :href="o.contactLinkedinProfile"
              target="_blank"
              class="text-cyan-500"
            >
              {{ o.contactName }}
            </a>
            <template v-else>{{ o.contactName }}</template>
          </td>
          <td class="border border-slate-300">{{ o.contactTitle }}</td>
          <td class="border border-slate-300">{{ o.contactCompanyName || 'N/A' }}</td>
          <td class="border border-slate-300">{{ o.adminNotes }}</td>
          <td class="border border-slate-300">
            <OpportunityFounderSelection
              :opportunity="o"
              :selected="o.founderSelected"
              :disabled="!list.isEditableByFounder || attemptingUpdate"
            />
          </td>
          <td class="border-none text-right">
            <button class="w-16" @click.prevent="openNotesModal(o)">
              {{ o.founderNotes ? 'Edit Note' : 'Add Note' }}
            </button>
          </td>
          <AddOpportunityNotesModal
            v-if="notesModalOpen"
            field="founderNotes"
            :opportunity="opportunityForNotes"
            @close="notesModalOpen = false"
            @update="updateOpportunity"
          />
        </tr>
      </tbody>
    </table>
    <SendFounderListFeedbackModal
      v-if="feedbackModalOpen"
      :list="list"
      @close="feedbackModalOpen = false"
    />
  </div>
  <div
    v-if="list && list.datetimeSalesforceHandoffCompleted"
    class="flex justify-center"
    :class="{ hidden: loading }"
  >
    <CollectionPagination
      :collection="opportunities"
      @is-loading-status-update="(isLoading) => (loading = isLoading)"
      pluralItemName="intros"
    />
  </div>
</template>

<script>
import CustomerList, { constants } from '@/services/customerLists'
import Opportunity from '@/services/opportunities'

import LoadingSpinner from '@/components/LoadingSpinner'
import Button from '@/components/inputs/Button'
import OpportunityFounderSelection from '@/components/inputs/OpportunityFounderSelection'
import SuccessBanner from '@/components/banners/SuccessBanner'
import InfoBanner from '@/components/banners/InfoBanner'
import SendFounderListFeedbackModal from '@/components/modals/SendFounderListFeedbackModal'
import AddOpportunityNotesModal from '@/components/modals/AddOpportunityNotesModal'
import CollectionPagination from '@/components/inputs/CollectionPagination'

export default {
  name: 'FounderCustomerListShow',
  components: {
    OpportunityFounderSelection,
    LoadingSpinner,
    Button,
    SuccessBanner,
    InfoBanner,
    SendFounderListFeedbackModal,
    AddOpportunityNotesModal,
    CollectionPagination,
  },
  data() {
    return {
      loading: true,
      attemptingUpdate: false,
      feedbackModalOpen: false,
      notesModalOpen: false,
      list: null,
      opportunities: Opportunity.createCollection({
        pagination: {
          size: this.$route.query.pageSize || 20,
          page: this.$route.query.page || 1,
        },
        filters: {
          listId: this.$route.params.id,
          ordering: this.$route.query.ordering || 'contact__name',
        },
      }),
    }
  },
  async created() {
    await this.retrieveList()
    await this.opportunities.refresh()
    this.loading = false
  },
  methods: {
    async retrieveList() {
      const list = await CustomerList.api.retrieve(this.$route.params.id)
      this.list = list
    },
    async confirmListSelections() {
      const confirmation = confirm('Confirm action: Confirm list selections.')
      if (!confirmation) return

      this.attemptingUpdate = true
      this.list = await CustomerList.api.update(this.list.id, {
        status: constants.APPROVED_BY_FOUNDER,
      })
      this.attemptingUpdate = false
      this.feedbackModalOpen = true
    },
    openNotesModal(opportunity) {
      this.opportunityForNotes = new Opportunity(JSON.parse(JSON.stringify(opportunity)))
      this.notesModalOpen = true
    },
    updateOpportunity(opportunity) {
      const list = this.opportunities.list.map((o) => (o.id === opportunity.id ? opportunity : o))
      this.opportunities.list = list
    },
    setPaginationQueryParams() {
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.opportunities.pagination.page,
          pageSize: this.opportunities.pagination.size,
          ordering: this.opportunities.filters.ordering,
        },
      })
    },
    updateOrdering(ordering) {
      let currentOrdering = this.$route.query.ordering || 'contact__name'
      if (currentOrdering === ordering) {
        ordering = '-' + ordering
      }
      this.$router.push({
        name: this.$route.name,
        query: {
          page: 1, // Reset to first page
          pageSize: this.opportunities.pagination.size,
          ordering,
        },
      })
    },
  },
  computed: {
    currentOrdering() {
      return this.$route.query.ordering || 'contact__name'
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler() {
        this.loading = true
        this.opportunities.filters.ordering = this.$route.query.ordering || 'contact__name'
        this.opportunities.pagination.page = this.$route.query.page || 1
        this.opportunities.pagination.size = this.$route.query.pageSize || 20
        await this.opportunities.refresh()
        this.loading = false
      },
    },
    'opportunities.pagination.page': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'opportunities.pagination.size': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
    'opportunities.filters.ordering': {
      immediate: true,
      deep: true,
      handler() {
        this.setPaginationQueryParams()
      },
    },
  },
}
</script>

<style scoped>
th,
td {
  padding: 0.5rem;
}
th {
  cursor: pointer;
}
</style>
